const stickyWidget = document.querySelector(".sticky-widget");
const contactButton = document.querySelectorAll(".contact-btn");

if (stickyWidget) {
  const widgetContainer = document.querySelector(".form-widget-container");
  const formExit = widgetContainer.querySelector(".form-exit");
  const widgetForm = widgetContainer.querySelector("form");
  const body = document.querySelector("body");
  const closeBtn = document.getElementById("form-modal-close");

  stickyWidget.addEventListener("click", function () {
    widgetContainer.style.display = "flex";
    body.style.overflow = "hidden";
    setTimeout(function () {
      widgetContainer.classList.add("active");
    }, 10);
  });

  contactButton.forEach((button) => {
    button.addEventListener("click", function () {
      widgetContainer.style.display = "flex";
      body.style.overflow = "hidden";
      setTimeout(function () {
        widgetContainer.classList.add("active");
      }, 10);
    });
  });

  formExit.addEventListener("click", function () {
    widgetContainer.classList.remove("active");
    setTimeout(function () {
      widgetContainer.style.display = "none";
      body.style.overflow = "auto";
    }, 300);
  });

  closeBtn.addEventListener("click", function () {
    widgetContainer.classList.remove("active");
    setTimeout(function () {
      widgetContainer.style.display = "none";
      body.style.overflow = "auto";
    }, 300);
  });

  widgetForm.addEventListener("submit", function (e) {
    e.preventDefault();

    const responseOutput = this.querySelector(".response-output");
    const spinner = this.querySelector(".loading-spinner");
    const submitButton = this.querySelector('input[type="submit"]');

    const form = this;

    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LekL1omAAAAAHOidDNPlJkxRYl4IMIVlwPmGWod", {
          action: "submit",
        })
        .then(async function (token) {
          // Add your logic to submit to your backend server here.

          let data = new FormData(form);
          data.append("action", "submit_contact_form");
          data.append("recaptcha_token", token);

          spinner.style.display = "block";
          submitButton.disabled = "disabled";

          const response = await fetch(techouse_scripts.ajax_url, {
            method: "POST",
            cache: "no-cache",
            body: data,
          });
          const jsonData = await response.json();

          if (jsonData["status"] === "ok") {
            responseOutput.innerText = jsonData["responseText"];
            responseOutput.classList.remove("error");
            form.reset();
          } else {
            responseOutput.innerText = jsonData["responseText"];
            responseOutput.classList.add("error");
          }

          spinner.style.display = "none";
          submitButton.removeAttribute("disabled");
        });
    });
  });
}
