import "./_body-view-height";
import "./header";
//import './_slideshow.js';

let skipHeader = document.querySelector("a.visually-hidden.skip-to-main");
if (skipHeader != undefined) {
  skipHeader.addEventListener("focusin", function (e) {
    this.classList.add("focused");
  });
  skipHeader.addEventListener("focusout", function (e) {
    this.classList.remove("focused");
  });
}

import "./_numbers.js";
import "./_modal-widget.js";
import "./_hero-slider-configuration.js";
import "./_hero-slider.js";
import "./_block-map.js";
