let numbers = document.querySelectorAll(".number");
let interval = 1500;
let i = 1;

//
//import intersectionObserve from "./_intersection-observer.js";
import { intersectionObserve as intersectionObserve } from "../src/_intersection-observer";

const elements = document.querySelectorAll(".numbers");

intersectionObserve(elements, 0, 0, (element, intersectionRatio) => {
  (function (el) {
    if (intersectionRatio > 0 && !el.classList.contains("fade-in")) {
      numbers.forEach((number) => {
        setTimeout(function () {
          let startValue = 0;
          let endValue = parseInt(number.getAttribute("data-val"));
          let duration = Math.floor(interval / endValue);
          let counter = setInterval(function () {
            startValue += 1;
            number.textContent = startValue;
            if (startValue == endValue) {
              clearInterval(counter);
            }
          }, duration);
        }, 100 * i);
        i++;
      });
    }
  })(element);
});

//
