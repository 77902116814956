// hamburger nav

const breakpoint = 1200;

window.addEventListener("load", (event) => {
  const body = document.querySelector("body");
  const button = document.querySelector(".menu-button");
  const header = document.querySelector("header");
  const wrapper = document.querySelector("header .wrapper");
  let outerHeight = window.outerHeight;
  let wrapperHeight = wrapper.offsetHeight;
  header.style.height = wrapperHeight + "px";

  button.onclick = function toggleMenu() {
    if (header.matches(".show")) {
      body.style.overflow = "visible";
      body.classList.remove("show-menu");
      header.classList.remove("anim");
      setTimeout(function () {
        header.classList.remove("show");
        header.style.height = wrapperHeight + "px";
      }, 300);
      button.classList.remove("menu-close");
    } else {
      body.style.overflow = "hidden";
      body.classList.add("show-menu");
      header.style.height = outerHeight + "px";
      header.classList.add("show");
      button.classList.add("menu-close");

      setTimeout(function () {
        header.classList.add("anim");
      }, 300);
    }
  };

  const headerLinks = document.querySelectorAll("header .menu-container a");
  const lastHeaderLink = document.querySelector(
    "header .menu-container .small-col .col:last-child a"
  );

  button.addEventListener("blur", (event) => {
    const NumList = [];
    headerLinks.forEach((link) => {
      setTimeout(function () {
        const isFocused = document.activeElement === link;

        NumList.push(isFocused);
        if (NumList.includes(true)) {
        } else {
          if (header.matches(".show")) {
            body.style.overflow = "visible";
            header.classList.remove("anim");
            setTimeout(function () {
              header.classList.remove("show");
              header.style.height = wrapperHeight + "px";
            }, 300);
            button.classList.remove("menu-close");
          }
        }
      }, 300);
    });
  });

  lastHeaderLink.addEventListener("blur", (event) => {
    const NumList = [];
    headerLinks.forEach((link) => {
      setTimeout(function () {
        const isFocused = document.activeElement === link;

        NumList.push(isFocused);
        setTimeout(function () {
          if (NumList.includes(true)) {
          } else {
            if (header.matches(".show")) {
              body.style.overflow = "visible";
              header.classList.remove("anim");
              setTimeout(function () {
                header.classList.remove("show");
                header.style.height = wrapperHeight + "px";
              }, 300);
              button.classList.remove("menu-close");
            }
          }
        }, 300);
      }, 300);
    });
  });
});