let heroSlider = document.querySelector(".hero-slider");

if(heroSlider) {
    let bigScreen = heroSlider.querySelector(".big-screen-hero");
    let images = bigScreen.querySelectorAll(".hero-image");
    let content = bigScreen.querySelectorAll(".content");
    let textContainer = bigScreen.querySelectorAll(".text-container");
    let text = bigScreen.querySelectorAll("p");
    let textHeight = 0;

    let prevSlide = 0;

    content[prevSlide].classList.add("active");
    images[prevSlide].classList.add("active");
    textContainer[prevSlide].style.height =  text[prevSlide].scrollHeight + 10 + "px";
    console.log(textContainer[prevSlide].style.height);

    window.addEventListener('resize', function(e) {
        textContainer[prevSlide].style.height =  text[prevSlide].scrollHeight+"px";
        for(let i=0; i<text.length; i++) {
            text[i].style.width = content[i].offsetWidth - 5 +'px';
        }
    });

    // Find max height of all text elements
    text.forEach(text => {
        if(text.scrollHeight > textHeight) {
            textHeight = text.scrollHeight;
        }
    });

    for(let i=0; i<text.length; i++) {
        text[i].style.width = content[i].offsetWidth - 5 +'px';
    }

    for(let y=0; y<content.length; y++) {
        content[y].addEventListener("mouseover", function() {
            content[prevSlide].classList.remove("active");
            images[prevSlide].classList.remove("active");
            textContainer[prevSlide].style.height = "0px";
            
            content[y].classList.add("active");
            images[y].classList.add("active");
            textContainer[y].style.height =  text[y].scrollHeight + "px";
            prevSlide = y;
        });
    };
}

