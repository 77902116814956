import { MarkerClusterer } from "@googlemaps/markerclusterer";

let map = null;
let zoomControlDiv = null;
let a15Bounds;
let a60Bounds;

const mapIcons = {
  circle:
    '<svg width="27" height="39" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.9996 13.7029C26.9996 6.13575 20.9554 0 13.4998 0C6.04421 0 0 6.13567 0 13.7029C0 15.2197 0.245014 16.6764 0.694458 18.0409H0.689384L0.697143 18.0518C1.37161 20.0948 13.5002 39 13.5002 39C13.5002 39 25.55 20.1888 26.2047 18.3441L26.2074 18.3408H26.2056C26.7186 16.8917 27 15.3303 27 13.7023L26.9996 13.7029ZM13.4998 22.6347C8.6395 22.6347 4.69934 18.6347 4.69934 13.7029C4.69934 8.77149 8.63927 4.77259 13.4998 4.77259C18.3595 4.77259 22.2995 8.77103 22.2995 13.7021C22.2995 18.6332 18.3596 22.634 13.4998 22.634V22.6347Z" fill="black"/></svg>',
};

const styles = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: "2.00",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#9c9c9c",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7b7b7b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#46bcec",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c8d7d4",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#070707",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
];

/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap($el) {
  const a15MinSW = new google.maps.LatLng(25.912704, 11.610856);
  const a15MinNE = new google.maps.LatLng(62.425141, 81.878318);
  a15Bounds = new google.maps.LatLngBounds(a15MinSW, a15MinNE);

  //   const a60MinSW = new google.maps.LatLng(58.629517, 5.524057);
  //   const a60MinNE = new google.maps.LatLng(59.239138, 6.376866);
  //a60Bounds = new google.maps.LatLngBounds(a60MinSW, a60MinNE);

  //   const startLocation = {
  //     lat: 30.9546197,
  //     lng: 1.6961458,
  //   };

  let markers = [];

  // Find marker elements within map.
  const $markers = $el.find(".marker");

  zoomControlDiv = $el.find(".custom-controls")[0];

  // Create gerenic map.
  const mapArgs = {
    // center: startLocation,
    zoom: 2,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: styles,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
  };
  map = new google.maps.Map($el[0], mapArgs);

  map.fitBounds(a15Bounds);

  // Add markers.
  $markers.each(function () {
    const marker = initMarker($(this), map);
    markers.push(marker);
  });

  // Add the control to the map at a designated control position
  // by pushing it on the position's array. This code will
  // implicitly add the control to the DOM, through the Map
  // object. You should not attach the control manually.
  //   map.controls[google.maps.ControlPosition.TOP_LEFT].push(zoomControlDiv);

  // Marker clustering
  //new MarkerClusterer({ map, markers, renderer: new CustomRenderer() });

  // Return map instance.
  return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker($marker) {
  // Get position from marker.
  const lat = $marker.data("lat");
  const lng = $marker.data("lng");
  //   const type = $marker.data("type");
  const name = $marker.data("name");
  const latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  let url = "data:image/svg+xml;charset=UTF-8;base64," + btoa(mapIcons.circle);
  let scaledSize = new google.maps.Size(30, 30);
  let anchor = null;

  const icon = {
    url: url,
    scaledSize: scaledSize, // scaled size
    optimized: false,
    anchor: anchor,
  };

  // Create marker instance.
  const marker = new google.maps.Marker({
    position: latLng,
    icon: icon,
    map: map,
  });

  // If marker contains HTML, add it to an infoWindow.
  if ($marker.html()) {
    // Create info window.
    var infowindow = new google.maps.InfoWindow({
      content: $marker.html(),
    });

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  }

  return marker;
}

window.initMaps = function () {
  // Render maps on page load.
  $(document).ready(function () {
    $(".acf-map").each(function () {
      initMap($(this));
    });
  });
};
